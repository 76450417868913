<template>
  <router-view></router-view>
</template>

<style>
h1 {
  font-size: 1.5rem;
  margin: 0.5rem 0;
}
.topbar {
  justify-content: left!important;
  padding: 0 20px;
}
.main {
  padding: 20px;
}
</style>